import { BusinessDetailsResponse } from '../responses/business-details.response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { ListBusinessResponse } from '../responses/list-business.response';
import { Observable } from 'rxjs';
import { PaginatedBusinessRecentAdditionsResponse } from '@app/responses';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Search';

  search(query: string): Observable<ListBusinessResponse> {
    return this.http.get<ListBusinessResponse>(this.rootURL + '?query=' + query);
  }
}