import { AnalyticsService } from "@app/services/analytics.service";
import { FunFacts } from "@app/models";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "@app/services";

interface FunFactsState {
    funfacts: FunFacts,    
    isLoading: boolean,
}


const initialState: FunFactsState = {
  funfacts: undefined,    
    isLoading: false,
}

@Injectable({
    providedIn: 'root',
  })
export class FunFactsStateService extends StateService<FunFactsState>{    

    
    funfacts$: Observable<FunFacts> = this.select((state)=>{
        return state.funfacts;
    });

    isLoading$: Observable<boolean> = this.select((state)=>{
        return state.isLoading;
    });
    
    constructor(private analyticsService: AnalyticsService){
        super(initialState);        
    }


    
  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  get(){
    this.setLoading(true);
    this.analyticsService.funfacts().subscribe(c => {
        this.setLoading(false);
        this.setState({funfacts: c.data});
    });
  }
  
  setLoading(value: boolean){
    this.setState({isLoading: value});
  }
}