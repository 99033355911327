<header class="header-area">
    <div class="navbar-area">
        <div class="louise-responsive-nav">
            <div class="container">
                <div class="louise-responsive-menu d-flex flex-row">
                    <div class="logo">
                        <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo">
                        </a>
                    </div>
                    <div class="container mt-2">
                        <div class="d-flex align-items-center">
                            <button class="btn btn-outline-light d-none d-lg-block" style="z-index: 10000;"
                                (click)="openSearch()">
                                <i class="bx bx-search-alt"></i>
                            </button>

                            <div class="option-item" *ngIf="$siteSettings | async as siteSettings">
                                <a [href]="siteSettings.adminUrl" class="default-btn"><i class="bx bx-plus"></i>
                                    Login</a>
                            </div>
                            <div class="option-item">
                                <a href="https://get-started.yebokenny.com" target="_blank" class="default-btn"><i
                                        class="bx bx-plus-circle"></i>
                                    Signup</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="louise-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/home">
                        <img src="assets/img/logo.png" alt="logo" style="height: 120px">
                    </a>

                    <div class="collapse navbar-collapse">
                        <ul class="navbar-nav">
                            <li class="nav-item"><a routerLink="/home" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Home</a>
                            </li>
                            <li class="nav-item"><a routerLink="/about-us" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">About Us</a>
                            </li>
                            <li class="nav-item"><a routerLink="/how-it-works" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">How it Works</a>
                            </li>
                            <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                            <li class="nav-item"><a routerLink="/feedback" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Feedback</a></li>
                            <li class="nav-item d-block d-sm-none" *ngIf="$siteSettings | async as siteSettings">
                                <a [href]="siteSettings.adminUrl" class="nav-link">
                                    <i class="bx bx-plus"></i>
                                    Login
                                </a>
                            </li>
                            <li class="nav-item d-block d-sm-none">
                                <a href="https://get-started.yebokenny.com" target="_blank" class="nav-link">
                                    <i class="bx bx-plus"></i>
                                    Signup
                                </a>
                            </li>
                            <li class="nav-item d-block d-sm-none">
                                <a href="/search-m" class="nav-link">
                                    <i class="bx bx-search-alt"></i>
                                    Search
                                </a>
                            </li>
                        </ul>

                        <div class="others-option">
                            <div class="d-flex align-items-center">
                                <button class="btn btn-outline-light d-none d-lg-block" style="z-index: 10000;"
                                    (click)="openSearch()">
                                    <i class="bx bx-search-alt"></i>
                                </button>

                                <div class="option-item" *ngIf="$siteSettings | async as siteSettings">
                                    <a [href]="siteSettings.adminUrl" class="default-btn"><i class="bx bx-plus"></i>
                                        Login</a>
                                </div>
                                <div class="option-item">
                                    <a href="https://get-started.yebokenny.com" target="_blank" class="default-btn"><i
                                            class="bx bx-plus-circle"></i>
                                        Signup</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</header>