import { AuthorInfoResponse } from '@app/responses';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthorInfoService {

  constructor(private http: HttpClient) { }

  get(businessId: string): Observable<AuthorInfoResponse> {
    return this.http.get<AuthorInfoResponse>(Keys.API_URL + 'api/AuthorInfo/' + businessId);
  }

}