<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6" *ngIf="info$ | async as info">
                <div class="single-footer-widget">
                    <h3>About YeboKenny</h3>

                    <div class="about-the-store">
                        <p>YeboKenny offers you the best recommendation system digitally</p>

                        <ul class="footer-contact-info">
                            <li><i class='bx bx-map'></i> Location: {{info.data.attributes.location}}</li>
                            <li><i class='bx bx-phone-call'></i> Call Us: <a
                                    [href]="'tel:'+ info.data.attributes.phoneNumber">{{info.data.attributes.phoneNumber}}</a>
                            </li>
                            <li><i class='bx bx-envelope'></i> Email Us: <a
                                    [href]="'mailto:' + info.data.attributes.email">{{info.data.attributes.email}}</a>
                            </li>
                        </ul>
                        <ul class="social-link">
                            <li><a href="https://www.tiktok.com/@yebokenny3" target="_blank"><i
                                        class='bx bxl-tiktok'></i></a></li>
                            <li><a [href]="info.data.attributes.facebookUrl" target="_blank"><i
                                        class='bx bxl-facebook'></i></a></li>
                            <li><a [href]="info.data.attributes.twitterUrl" target="_blank"><i
                                        class='bx bxl-twitter'></i></a></li>
                            <li><a [href]="info.data.attributes.instagramUrl" target="_blank"><i
                                        class='bx bxl-instagram'></i></a></li>
                            <li><a [href]="info.data.attributes.linkedInUrl" target="_blank"><i
                                        class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-4">
                    <h3>Quick Links</h3>

                    <ul class="quick-links">
                        <!-- <li><a routerLink="/about">About Us</a></li> -->
                        <!-- <li><a routerLink="/listing-one">Listing</a></li> -->
                        <li><a routerLink="/how-it-works">How It Works</a></li>
                        <li><a routerLink="/faq">FAQs</a></li>
                        <li><a routerLink="/contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Customer Support</h3>

                    <ul class="customer-support">
                        <li *ngIf="$siteSettings | async as siteSettings">
                            <a [href]="siteSettings.adminUrl +'/my-profile'">My Account</a>
                        </li>
                        <li><a routerLink="/faq">FAQs</a></li>
                        <li><a routerLink="/contact">Help & Support</a></li>
                    </ul>
                </div>
            </div>

            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Newsletter</h3>

                    <div class="footer-newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>

                        <form class="newsletter-form" data-toggle="validator">
                            <label>Your E-mail Address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>©YeboKenny, Positive Referencing for Business Excellence</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms-and-conditions">Terms & Conditions</a></li>
                        <li><a routerLink="/user-account/deactivation/request">Account Deactivation</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>

            <div class="search-overlay-close" (click)="closeOverlay()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form [formGroup]="form">
                    <input type="text" class="input-search" [formControlName]="'querySearch'"
                        placeholder="Search here...">
                    <button type="button" (click)="submit()"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
    <div class="search-overlay-results" style="z-index: 1000;">
        <app-spinner *ngIf="isLoading$ | async"></app-spinner>
        <div *ngIf="!(isLoading$ | async)">
            <div *ngIf="businesses$ | async as businesses">
                <div class="row" *ngIf="businesses.length > 0; else no_results">
                    <div *ngFor="let business of businesses" class="col-lg-4 col-sm-12 col-md-6 grid-item">
                        <div class="single-listing-item" (click)="navigateToItem(business.id)">
                            <div class="listing-image">
                                <a [routerLink]="'/listing-details/' + business.id" class="d-block">
                                    <img [src]="business.profileImage?.originalUrl ?? 'assets/img/listing/img1.jpg'"
                                        alt="image">
                                </a>

                                <div class="listing-tag">
                                    <a routerLink="/" class="d-block">{{business.categoryName}}</a>
                                </div>

                                <div class="listing-rating">
                                    <div class="rating-total">
                                    </div>
                                </div>
                            </div>

                            <div class="listing-content">
                                <div class="listing-author d-flex align-items-center">
                                    <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                    <span>{{business.authorName}}</span>
                                </div>

                                <h3><a routerLink="/listing-details" class="d-inline-block">{{business.name}}</a></h3>


                            </div>

                            <div class="listing-box-footer">
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="price">
                                        <span data-toggle="tooltip" data-placement="top" title="Ultra Hight">
                                            {{business.emailAddress}}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="listing-badge">{{business.businessTypeReadable}}</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ng-template #no_results>
    <app-no-results *ngIf="hasCompletedFirstSearch$ | async"></app-no-results>
</ng-template>