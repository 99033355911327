import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-country-redirect',
    templateUrl: './country-redirect.component.html',
})
export class CountryRedirectComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        const userCountryCode = localStorage.getItem('userCountryCode');
        this.router.navigateByUrl(`${userCountryCode}/home`); // Redirect to ':countryCode/home'
    }
}
