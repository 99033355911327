import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { GlobalStateService } from '@app/global.state';
import { Observable } from 'rxjs';
import { OverlayService } from '@app/services';
import { SiteSettings } from '@app/models';

@Component({
  selector: 'app-header-three',
  templateUrl: './header-three.component.html',
  styleUrls: ['./header-three.component.scss']
})
export class HeaderThreeComponent implements OnInit {

  $siteSettings: Observable<SiteSettings> = this._state.siteSettings$;
  isMobile: boolean;

  constructor(
    private _state: GlobalStateService,
    private _overlayService: OverlayService,
    private breakpointObserver: BreakpointObserver,
    private cdRef: ChangeDetectorRef
  ) {
    // Initialize isMobile based on the initial window width
    this.isMobile = this.breakpointObserver.isMatched(Breakpoints.Handset);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 500);
    // Subscribe to changes in the 'handset' breakpoint
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.Medium,])
      .subscribe(result => {
        this.isMobile = result.matches;

        // Manually trigger change detection if needed
        this.cdRef.detectChanges();
      });
  }

  openSearch() {
    this._overlayService.open();
  }
}
