import { CategoryListResponse } from '../responses/category-list.response';
import { ConfirmationResponse } from '../responses/confirmation.response';
import { CreateRecommendationRequest } from '../requests/create-recommendation.request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecommendationService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Recommendation';

  create(request: CreateRecommendationRequest): Observable<ConfirmationResponse> {
    return this.http.post<ConfirmationResponse>(this.rootURL + '/Create', request);
  }

  getIPAddress(): Observable<any> {
    return this.http.get("https://api.ipify.org/?format=json")
  }
}