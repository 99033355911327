import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  constructor(
    private _router: Router,
  ) {
  }

  open() {
    this._router.navigateByUrl('search-m');
  }

  close() {
    this._router.navigate(['../']);
  }
}