<section class="funfacts-area pt-100 pb-70 jarallax" data-jarallax='{"speed": 0.3}'*ngIf="$info | async as info">
    <div class="container">
        <div class="row" >
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-group'></i>
                    <p>Users</p>
                    <h3 class="odometer" [attr.data-count]="info.numberOfUsers">{{info.numberOfUsers}}</h3>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-bullseye'></i>
                    <p>Recommendations</p>
                    <h3 class="odometer" [attr.data-count]="info.numberOfRecommendations">{{info.numberOfRecommendations}}</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-shape-polygon'></i>
                    <p>Listings</p>
                    <h3 class="odometer" [attr.data-count]="info.numberOfListings">{{info.numberOfListings}}</h3>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-3 col-sm-6">
                <div class="single-funfacts">
                    <i class='bx bx-trophy'></i>
                    <p>Views</p>
                    <h3 class="odometer" [attr.data-count]="info.numberOfViews">{{info.numberOfViews}}</h3>
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>