import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { PolicyResponse } from '@app/responses';
import { TncStateService } from './tnc.state';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
 
  
  policy$: Observable<PolicyResponse> = this._state.policy$;
  
  constructor(private _state: TncStateService){}
  
  ngOnInit(): void {
    this._state.getPolicy();
  }
}
