import { Component, OnInit } from '@angular/core';

import { AppProcessResponse } from '@app/responses';
import { Observable } from 'rxjs';
import { ProcessStateService } from './process.state';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

  $info: Observable<AppProcessResponse> = this._state.process$;
   
  constructor(private _state: ProcessStateService) { }

  ngOnInit(): void {
    this._state.getInfo();
  }

}
