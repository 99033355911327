<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Download App</span>
                    <h2>Supporting Your Customers On The Go With Our Mobile App</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                    <div class="btn-box">
                        <a routerLink="/" class="apple-store-btn">
                            <img src="assets/img/applestore.png" alt="image">
                            Download on the
                            <span>Apple Store</span>
                        </a>

                        <a routerLink="/" class="play-store-btn">
                            <img src="assets/img/playstore.png" alt="image">
                            Get it on
                            <span>Google Play</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>