import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner.component';

@NgModule({
    declarations: [
        SpinnerComponent,
    ],
    exports:[
        SpinnerComponent,
    ]
})
export class SpinnerModule {
}