import { CommonModule } from '@angular/common';
import { InstagramComponent } from '@app/components/common/instagram/instagram.component';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [
        InstagramComponent,
    ],
    exports:[
        InstagramComponent,
    ],
    imports:[
        CommonModule,
    ]
})
export class InstagramModule {
}