import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { NoResultsModule } from '@app/components/common/no-results/no-results.module';
import { RouterModule } from '@angular/router';
import { SpinnerModule } from '@app/components/common/spinner/spinner.module';

@NgModule({
    declarations: [
        FooterComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        SpinnerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NoResultsModule,
    ],
    exports: [
        FooterComponent
    ],
})
export class FooterModule {
}
