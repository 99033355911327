import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PolicyResponse } from "@app/responses";
import { StateService } from "@app/services";
import { StrapiService } from "@app/services/strapi.service";

interface TncState {
    policy: PolicyResponse,    
    isLoading: boolean,
}


const initialState: TncState = {
    policy: undefined,    
    isLoading: false,
}

@Injectable({
    providedIn: 'root',
  })
export class TncStateService extends StateService<TncState>{    

    
    policy$: Observable<PolicyResponse> = this.select((state)=>{
        return state.policy;
    });

    isLoading$: Observable<boolean> = this.select((state)=>{
        return state.isLoading;
    });
    
    constructor(private strapiService: StrapiService){
        super(initialState);        
    }

  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  getPolicy(){
    this.setLoading(true);
    this.strapiService.getTncPolicy()
    .subscribe(c => {
        this.setLoading(false);
        this.setState({policy: c});
    });
  }

  setLoading(value: boolean){
    this.setState({isLoading: value});
  }
}