import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable()
export class CountryCodeInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Check if the environment is not production and the request is 'localhost:7125'        
        if (request.url.includes(Keys.CHECK_URL)) {
            // Get the user's country code from local storage
            const userCountryCode = localStorage.getItem('userCountryCode') ?? 'ZA';

            // Clone the request and add the X-Country-Code header if the country code is available
            if (userCountryCode) {
                request = request.clone({
                    setHeaders: {
                        'X-Country-Code': userCountryCode,
                    },
                });
            }
        }
        return next.handle(request);
    }
}
