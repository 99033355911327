import { Business, SiteSettings } from '@app/models';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ContactUsResponse } from '@app/responses';
import { FooterStateService } from './footer.state';
import { GlobalStateService } from '@app/global.state';
import { Observable } from 'rxjs';
import { OverlayService } from '@app/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  form: UntypedFormGroup;
  businesses$ : Observable<Business[]> = this._state.businesses$;
  isLoading$  : Observable<boolean> = this._state.isLoading$;
  hasCompletedFirstSearch$  : Observable<boolean> = this._state.hasCompletedFirstSearch$;
  info$: Observable<ContactUsResponse> = this._state.contact$;
  $siteSettings: Observable<SiteSettings> = this._stateGlobal.siteSettings$;
  constructor(private _state: FooterStateService,
    private _stateGlobal: GlobalStateService, 
    private _formBuilder: UntypedFormBuilder,
    private _overlayService: OverlayService,
    private _router: Router) { }

  ngOnInit(): void {
    this._buildForm();
    this._state.getInfo();
  }

  _buildForm(){
    this.form = this._formBuilder.group({
      querySearch         : ['',],
    });
  }

  closeOverlay(){
    this._overlayService.close();  
    this._state.reset();
    this.form.controls.querySearch.setValue('');
  }

  navigateToItem(id: string){
    this.closeOverlay();
    this._router.navigate(['/listing-details/' + id]);
  }


  submit(){
    this._state.search(this.form.controls.querySearch.value);
  }

}
