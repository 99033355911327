<app-header-three></app-header-three>

<div class="page-title-area page-title-bg3">
    <div class="container">
        <div class="page-title-content">
            <h2>About Us</h2>
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2>Who are we</h2>
                    <p>
                        <strong>Yebokenny</strong> is an innovative <strong>digital platform</strong> that brings the
                        traditional practice of
                        word-of-mouth referrals into the modern age. It offers a dynamic space for customers to share
                        their genuine experiences and recommendations about various products, services, places, and
                        more. This platform is the ultimate destination for unbiased, user-generated recommendations
                        that assist customers in making informed decisions and uncovering hidden treasures. Yebokenny is
                        here to revolutionize the way businesses flourish and succeed in today's interconnected world.
                    </p>

                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>Positive referencing through business excellence</h5>
                        <p>More than just a local listing, your free Business Profile lets you manage how your business
                            appears on <strong>YeboKenny</strong> Search.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/P8zb0Fqf1po?si=yym8Ifrad0H88Q1g"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="about-text">
                        <h3>Our Mission</h3>
                        <p>Our innovative platform empowers businesses of all sizes to tap into and transform the
                            potential of authentic word-of-mouth marketing. We make it effortless, adaptable, and
                            game-changing for their business expansion.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Get Started</h3>
                        <p>How to get started with YeboKenny.</p>

                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Simply click the <a href="https://get-started.yebokenny.com"
                                    target="_blank">link</a></li>
                            <li><i class='bx bx-check'></i> Create an account</li>
                            <li><i class='bx bx-check'></i> Register your business details</li>
                            <li><i class='bx bx-check'></i> YeboKenny will approve your profile within 24 hours</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="about-text">
                        <h3>Pricing and plans</h3>
                        <p>Pay for what you need.</p>

                        <ul class="features-list">
                            <li><i class='bx bx-check'></i>YeboKenny is free to use</li>
                            <li><i class='bx bx-check'></i> Upgrade your plan to get powerful features</li>
                            <li><i class='bx bx-check'></i>Register your business products and services with direct
                                links to your existing platforms</li>
                            <li><i class='bx bx-check'></i>Need a website? Stay tuned to YeboKenny to find out more</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="about-text">
                        <h3>Our Promise</h3>
                        <p>When you make it easy to do business, your business grows.</p>

                        <ul class="features-list">
                            <li><i class='bx bx-check'></i> Quality over quantity</li>
                            <li><i class='bx bx-check'></i> We ensure legitamacy of businesses</li>
                            <li><i class='bx bx-check'></i> Online listings of products and services</li>
                            <li><i class='bx bx-check'></i> A mobile app for ease of use</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<h2 style="text-align:center">YeboKenny Pricing Plans</h2>

<div class="container">
    <div class="row" *ngIf="$plans |async as plans">
        <div class="columns" *ngFor="let plan of plans">
            <ul class="price">
                <li class="header">{{plan.name}}</li>
                <li class="grey">R {{plan.price}} / {{getPeriodType(plan.periodType)}}</li>
                <li class="grey-description">{{plan.description}}</li>
                <li *ngFor="let feature of plan.features">{{feature.friendlyName}}</li>
            </ul>
        </div>
    </div>
</div>

<app-funfacts></app-funfacts>


<app-instagram></app-instagram>