import { BusinessService, StateService } from "@app/services";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PlanDto } from "@app/models";

interface AboutState {
    availablePlans: PlanDto[],
}

const initialState: AboutState = {
    availablePlans: [],
}

@Injectable({
    providedIn: 'root',
  })
export class AboutStateService extends StateService<AboutState>{    
      
    availablePlans$: Observable<PlanDto[]> = this.select((state)=>{
        if(state.availablePlans === undefined){
            this.plans();
        }
        return state.availablePlans;
    });
      
    
    constructor(private _businessService: BusinessService){
        super(initialState);
    }
// ------------------------------------------------------------------------------------------
// @ API CALLS
// ------------------------------------------------------------------------------------------

	
	plans(){
		this._businessService.getPlans().subscribe((response)=>{
			this.setState({availablePlans: response.data});
		});
	}
}