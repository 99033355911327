import { FunFactsResponse } from '@app/responses';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor(private http: HttpClient) { }

    track(businessId: string): Observable<any> {
        return this.http.post<any>(Keys.API_URL + 'api/Analytics/', {
            businessId: businessId,
        });
    }

    funfacts(): Observable<FunFactsResponse> {
        return this.http.get<FunFactsResponse>(Keys.API_URL + 'api/Analytics/FunFacts',);
    }

}