import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import Swal from 'sweetalert2'

/**
 * Base state class allowing the ability to manage complex state within a single page component
 */
export class StateService<T> {
  private state$: BehaviorSubject<T>;

  /*
  *returns the current state snapshot
  */
  protected get state(): T {
    return this.state$.getValue();
  }
  /*
  *The constructor takes an initial state and initializes the BehaviorSubject
  */
  constructor(initialState: T) {
    this.state$ = new BehaviorSubject<T>(initialState);
  }

  /*
  *takes a callback function. That function is called when state$ emits a new state. Within RxJS map the callback function 
  *will return a piece of state. distinctUntilChanged will skip emissions until the selected piece of state holds a new value/
  *object reference
  */
  protected select<K>(mapFn: (state: T) => K): Observable<K> {
    return this.state$.asObservable().pipe(
      map((state: T) => mapFn(state)),
      distinctUntilChanged()
    );
  }

  /*
  *accepts a Partial Type. This allows us to be lazy and pass only some properties of a bigger state interface. Inside the
  *state$.next method the partial state is merged with the full state object. Finally the BehaviorSubject this.state$ will *emit a brand new state object
  */
  protected setState(newState: Partial<T>) {
    this.state$.next({
      ...this.state,
      ...newState,
    });
  }

  public alertUI(message: string, icon: 'success' | 'error' | 'warning' | 'info' | 'question', timer?: number) {
    Swal.fire({
      position: 'top-end',
      icon: icon,
      title: message,
      showConfirmButton: false,
      timer: timer ?? 1500,
    })
  }

  public alertUIConfirm(title: string, message: string, icon: 'success' | 'error' | 'warning' | 'info' | 'question', callback: Function) {
    Swal.fire({
      title: title,
      text: message,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#4f46e5',
      cancelButtonColor: '#dc2626',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
        confirm();
      }
    })
  }

  getCurrentState(): T {
    return this.state;
  }
}
