import { Component, OnInit } from '@angular/core';

import { ContactStateService } from '@app/components/pages/contact/contact.state';
import { ContactUsResponse } from '@app/responses';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {
  info$: Observable<ContactUsResponse> = this._state.contact$;
  constructor(private _state: ContactStateService,) { }

  ngOnInit(): void {
    this._state.getInfo();
  }

}
