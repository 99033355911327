import { CreateLeadRequest, SubmitFormRequest } from "@app/requests";
import { LeadService, StateService } from "@app/services";

import { ContactUsResponse } from "@app/responses";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StrapiService } from "@app/services/strapi.service";

interface ContactState {
  contact: ContactUsResponse,
  isLoading: boolean,
}


const initialState: ContactState = {
  contact: undefined,
  isLoading: false,
}

@Injectable({
  providedIn: 'root',
})
export class ContactStateService extends StateService<ContactState>{


  contact$: Observable<ContactUsResponse> = this.select((state) => {
    return state.contact;
  });

  isLoading$: Observable<boolean> = this.select((state) => {
    return state.isLoading;
  });

  constructor(private strapiService: StrapiService, private leadService: LeadService) {
    super(initialState);
  }



  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  load() {
    this.getInfo();
  }

  getInfo() {
    this.setLoading(true);
    this.strapiService.getContactInfo().subscribe(c => {
      this.setLoading(false);
      this.setState({ contact: c });
    });
  }


  submit(request: CreateLeadRequest, callback: Function) {
    this.setLoading(true);
    this.leadService.create(request).subscribe(c => {
      this.setLoading(false);
      this.alertUI('Submitted', 'success');
      callback();
    });
  }

  setLoading(value: boolean) {
    this.setState({ isLoading: value });
  }
}