import { ApiResponse, FeedbackResponse } from '@app/responses';

import { ConfirmationModel } from '../responses/confirmation.response';
import { CreateLeadRequest } from '@app/requests';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LeadService {

    constructor(private http: HttpClient) { }

    rootURL = Keys.API_URL + 'api/Lead';

    create(request: CreateLeadRequest): Observable<any> {
        return this.http.post<any>(this.rootURL + '/Create', request);
    }
}