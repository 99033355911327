import { NgModule } from '@angular/core';
import { NoResultsComponent } from './no-results.component';

@NgModule({
    declarations: [
        NoResultsComponent,
    ],
    exports: [
        NoResultsComponent,
    ]
})
export class NoResultsModule {
}
