import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GeoLocationService {
    private apiUrl = 'https://ipinfo.io/json';

    constructor(private http: HttpClient) {}

    getUserCountryCode(): Observable<any> {
        return this.http.get(this.apiUrl);
    }
}
