<section class="process-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Process</span>
            <h2>See How It Works</h2>
        </div>

        <div class="row" *ngIf="$info|async as info">
            <div class="col-lg-4 col-sm-6 col-md-6" *ngFor="let kv of info.data.attributes.keyval; let i = index">
                <div class="single-process-box">
                    <div class="icon">
                        <i class="flaticon-tap"></i>
                    </div>
                    <div class="content">
                        <h3>{{kv.key}}</h3>
                        <p>{{kv.val}}</p>
                        <div class="number mb-2">{{i+1}}</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="process-arrow-icon">
                    <img src="assets/img/arrow.png" alt="image">
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>