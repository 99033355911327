import { RouteReuseStrategy, Router, RouterModule, Routes } from '@angular/router';

import { AboutComponent } from './components/pages/about/about.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { HowItWorksComponent } from './components/pages/how-it-works/how-it-works.component';
import { InitialDataResolver } from './app.resolvers';
import { NgModule } from '@angular/core';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { httpToHttpsRedirectGuard } from './guards/http-to-https-redirect.guard';

const routes: Routes = [
	{ path: '', pathMatch: 'full', redirectTo: 'home' },
	{
		path: '',
		canActivate: [httpToHttpsRedirectGuard],
		resolve: {
			initialData: InitialDataResolver,
		},
		children: [
			{ path: 'home', loadChildren: () => import('src/app/components/pages/home/home.module').then(m => m.HomeModule) },
			{ path: 'search-m', loadChildren: () => import('src/app/components/pages/mobile-search/mobile-search.module').then(m => m.MobileSearchModule) },
			{ path: 'terms-and-conditions', component: TermsAndConditionsComponent },
			{ path: 'privacy-policy', component: PrivacyPolicyComponent },
			{ path: 'how-it-works', component: HowItWorksComponent },
			{ path: 'about-us', component: AboutComponent },
			{ path: 'categories', loadChildren: () => import('src/app/components/pages/categories/categories.module').then(m => m.CategoriesModule) },
			{ path: 'listing-details/:id', loadChildren: () => import('src/app/components/pages/listing-details/listing-details.module').then(m => m.ListingDetailsModule) },
			{ path: 'faq', loadChildren: () => import('src/app/components/pages/faq/faq.module').then(m => m.FaqModule) },
			{ path: 'contact', loadChildren: () => import('src/app/components/pages/contact/contact.module').then(m => m.ContactModule) },
			{ path: 'feedback', loadChildren: () => import('src/app/components/pages/feedback/feedback.module').then(m => m.FeedbackModule) },
			{ path: 'listing/:categoryId', loadChildren: () => import('src/app/components/pages/listing/listing.module').then(m => m.ListingModule) },
			{ path: 'user-account/deactivation/request', loadChildren: () => import('src/app/components/pages/account-deactivation/account-deactivation.module').then(m => m.AccountDeactivationModule) },
			{ path: 'recommend/:businessId/:surveyId', loadChildren: () => import('src/app/components/pages/recommendation/recommendation.module').then(m => m.RecommendationModule) },
			{ path: 'recommend-item/:businessId/:businessItemId', loadChildren: () => import('src/app/components/pages/recommendation-item/recommendation-item.module').then(m => m.RecommendationItemModule) },
			{ path: '**', component: ErrorComponent },
		]
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {})],
	exports: [RouterModule],
	providers: [InitialDataResolver]
})
export class AppRoutingModule {
	constructor(private router: Router, private routeReuseStrategy: RouteReuseStrategy) {
		// Configure route reuse strategy if needed
		this.router.routeReuseStrategy = this.routeReuseStrategy;
	}
}