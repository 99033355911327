import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { PolicyResponse } from '@app/responses';
import { PrivacyStateService } from './pp.state';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit{
  
  policy$: Observable<PolicyResponse> = this._state.policy$;

  constructor(private _state: PrivacyStateService){
    
  }
  
  ngOnInit(): void {
    this._state.getPolicy();
  }

  
}
