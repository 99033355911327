import { CategoryListResponse } from '../responses/category-list.response';
import { ConfirmationResponse } from '../responses/confirmation.response';
import { CreateRecommendationRequest } from '../requests/create-recommendation.request';
import { FeedbackResponse } from '@app/responses';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Feedback';

  get(id: string): Observable<FeedbackResponse> {
    return this.http.get<FeedbackResponse>(this.rootURL + '/Feedback/' + id);
  }

  getItem(id: string): Observable<FeedbackResponse> {
    return this.http.get<FeedbackResponse>(this.rootURL + '/FeedbackItem/' + id);
  }
}