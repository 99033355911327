import { AppProcessResponse, ContactUsResponse, FaqResponse, PolicyResponse, SubmitFormResponse, VideoResponse } from '@app/responses';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';
import { SubmitFormRequest } from '@app/requests';

@Injectable({
  providedIn: 'root'
})
@Injectable({
  providedIn: 'root'
})
export class StrapiService {
  constructor(private http: HttpClient) { }

  rootURL = Keys.STRAPI_URL;

  getFaqs(): Observable<FaqResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${Keys.strapi}`);
    return this.http.get<FaqResponse>(this.rootURL + '/faqs', { headers });
  }

  getContactInfo(): Observable<ContactUsResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${Keys.strapi}`);
    return this.http.get<ContactUsResponse>(this.rootURL + '/contact-us', { headers });
  }

  submitForm(formData: SubmitFormRequest): Observable<SubmitFormResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${Keys.strapi}`);
    return this.http.post<SubmitFormResponse>(this.rootURL + '/ezforms/submit', formData, { headers });
  }

  getAppProcessData(): Observable<AppProcessResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${Keys.strapi}`);
    return this.http.get<AppProcessResponse>(`${this.rootURL}/app-process?populate=*`, { headers });
  }

  getPrivacyPolicy(): Observable<PolicyResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${Keys.strapi}`);
    return this.http.get<PolicyResponse>(`${this.rootURL}/privacy-policy`, { headers });
  }

  getTncPolicy(): Observable<PolicyResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${Keys.strapi}`);
    return this.http.get<PolicyResponse>(`${this.rootURL}/terms-and-condition`, { headers });
  }

  getVideo(): Observable<VideoResponse> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${Keys.strapi}`);
    return this.http.get<VideoResponse>(`${this.rootURL}/video`, { headers });
  }
}