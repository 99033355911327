import { ApiResponse, BusinessHourResponse, PaginatedBusinessRecentAdditionsResponse } from '@app/responses';

import { BusinessDetailsResponse } from '../responses/business-details.response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { ListBusinessResponse } from '../responses/list-business.response';
import { Observable } from 'rxjs';
import { PlanDto } from '@app/models';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Business';

  list(categoryId: string): Observable<ListBusinessResponse> {
    return this.http.get<ListBusinessResponse>(this.rootURL + '/List/' + categoryId);
  }

  details(businessId: string): Observable<BusinessDetailsResponse> {
    return this.http.get<BusinessDetailsResponse>(this.rootURL + '/Details/' + businessId);
  }

  recentAdditions(pageNumber: number): Observable<PaginatedBusinessRecentAdditionsResponse> {
    return this.http.get<PaginatedBusinessRecentAdditionsResponse>(this.rootURL + '/RecentAdditions/' + environment.pageSize + '/' + pageNumber);
  }

  getBusinessHours(businessId: string): Observable<BusinessHourResponse> {
    return this.http.get<BusinessHourResponse>(Keys.API_URL + 'api/BusinessHour/' + businessId);
  }


  getPlans(): Observable<ApiResponse<PlanDto[]>> {
    return this.http.get<ApiResponse<PlanDto[]>>(Keys.API_URL + 'api/Admin/Plans');
  }
}