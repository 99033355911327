<app-header-three></app-header-three>

<div class="page-title-area page-title-bg4">
    <div class="container">
        <div class="page-title-content">
            <h2>How to YeboKenny</h2>
        </div>
    </div>
</div>

<app-process></app-process>


<h2 style="text-align:center">YeboKenny Pricing Plans</h2>

<div class="container">
    <div class="row" *ngIf="$plans |async as plans">
        <div class="columns" *ngFor="let plan of plans">
            <ul class="price">
                <li class="header">{{plan.name}}</li>
                <li class="grey">R {{plan.price}} / {{getPeriodType(plan.periodType)}}</li>
                <li class="grey-description">{{plan.description}}</li>
                <li *ngFor="let feature of plan.features">{{feature.friendlyName}}</li>
            </ul>
        </div>
    </div>
</div>

<app-funfacts></app-funfacts>

<app-video></app-video>

<!-- <app-partner></app-partner> -->