import { Component, OnInit } from '@angular/core';

import { FunFacts } from '@app/models';
import { FunFactsStateService } from './process.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-funfacts',
  templateUrl: './funfacts.component.html',
  styleUrls: ['./funfacts.component.scss']
})
export class FunfactsComponent implements OnInit {

  $info: Observable<FunFacts> = this._state.funfacts$;

  constructor(private _state: FunFactsStateService) { }

  ngOnInit(): void {
    this._state.get();
  }

}
