import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { GeoLocationService } from './services/geolocation.service';
import { GlobalStateService } from './global.state';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { SiteSettings } from './models';
import { filter } from 'rxjs/operators';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    $siteSettings: Observable<SiteSettings> = this._state.siteSettings$;
    userCountryCode: string;

    constructor(
        private router: Router,
        private _state: GlobalStateService,
        private _geoLocationService: GeoLocationService,
        private _snackBar: MatSnackBar,
        private _gaService: GoogleAnalyticsService
    ) {
    }

    ngOnInit() {
        this.recallJsFuntions();
        // Check if the user's country code is already in local storage
        const storedCountryCode = localStorage.getItem('userCountryCode');

        if (storedCountryCode) {

            this.userCountryCode = storedCountryCode;
        } else {
            this.userCountryCode = 'ZA';
            localStorage.setItem('userCountryCode', 'ZA');
            // If not in local storage, fetch it and store it
            // this._geoLocationService.getUserCountryCode().subscribe((data: any) => {
            //     this.userCountryCode = data.country;
            //     // Store the user's country code in local storage                
            // });
        }
        // var snackbarRef = this._snackBar.open('Get Started with YeboKenny', 'Login / Signup Now', {
        //     panelClass: ['blue-snackbar'],
        // });
        // snackbarRef.onAction().subscribe(() => {
        //     this._gaService.event('login_signup', 'call_to_action', 'Login / Signup To YeboKenny');
        //     window.location.href = this._state.getCurrentState().siteSettings.adminUrl;
        // });
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                $.getScript('../assets/js/main.js');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}