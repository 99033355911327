import { ApiResponse } from '@app/responses';
import { BusinessItemModel } from '@app/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BusinessItemService {

    constructor(private http: HttpClient) { }

    rootURL = Keys.API_URL + 'api/BusinessItem';

    list(businessId: string): Observable<ApiResponse<BusinessItemModel[]>> {
        return this.http.get<ApiResponse<BusinessItemModel[]>>(this.rootURL + '/List/' + businessId);
    }
    
    get(businessItemId: string): Observable<ApiResponse<BusinessItemModel>> {
        return this.http.get<ApiResponse<BusinessItemModel>>(this.rootURL + '/Get/' + businessItemId);
    }
}