import { CategoryDetailsResponse } from '../responses/category-details.response';
import { CategoryListResponse } from '../responses/category-list.response';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Keys } from '@app/constants';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  rootURL = Keys.API_URL + 'api/Category';

  getTopLevelCategories(): Observable<CategoryListResponse> {
    return this.http.get<CategoryListResponse>(this.rootURL + '/GetTopLevel');
  }

  getSubCategories(categoryId: string): Observable<CategoryListResponse> {
    return this.http.get<CategoryListResponse>(this.rootURL + '/Get/' + categoryId);
  }

  details(categoryId: string): Observable<CategoryDetailsResponse> {
    return this.http.get<CategoryDetailsResponse>(this.rootURL + '/Details/' + categoryId);
  }
}