<app-header-three></app-header-three>

<div class="page-title-area page-title-bg4">
    <div class="container">
        <div class="page-title-content">
            <h2>Privacy Policy</h2>
        </div>
    </div>
</div>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12" *ngIf="policy$ | async as policy">
                <div [innerHTML]="policy.data.attributes.value"></div>
            </div>
        </div>
    </div>
</section>