<div class="coming-soon-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="coming-soon-content">
                <a routerLink="/" class="logo">
                    <img src="assets/img/black-logo.png" alt="image">
                </a>
                <h2>YeboKenny is Currently in Maintenance</h2>
                <div id="timer" class="flex-wrap d-flex justify-content-center">
                    <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                        <span>Days</span>
                    </div>
                    <div id="hours" class="align-items-center flex-column d-flex justify-content-center">{{hours}}
                        <span>Hours</span>
                    </div>
                    <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">{{minutes}}
                        <span>Minutes</span>
                    </div>
                    <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">{{seconds}}
                        <span>Seconds</span>
                    </div>
                </div>
                <div class="newsletter-form">
                    <p class="mt-5">If you need to get in contact with us please fill out the form below</p>
                    <form [formGroup]="form">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Name <span>*</span></label>
                                    <input type="text" formControlName="name" class="form-control"
                                        placeholder="Your name">
                                    <div *ngIf="form.get('name').hasError('required') && form.get('name').touched"
                                        class="text-danger pt-1">
                                        This field is required
                                    </div>
                                    <div *ngIf="form.get('name').hasError('minlength') && form.get('name').touched"
                                        class="text-danger pt-1">
                                        Minimum length of 3 characters required
                                    </div>
                                    <div *ngIf="form.get('name').hasError('maxlength') && form.get('name').touched"
                                        class="text-danger pt-1">
                                        Maximum length of 20 characters exceeded
                                    </div>
                                    <div *ngIf="form.get('name').hasError('isProfane') && form.get('name').touched"
                                        class="text-danger pt-1">
                                        Please refrane from profanity
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <label>Email <span>*</span></label>
                                    <input type="email" formControlName="email" class="form-control"
                                        placeholder="Your email address">
                                    <div *ngIf="form.get('email').hasError('required') && form.get('email').touched"
                                        class="text-danger pt-1">
                                        This field is required
                                    </div>
                                    <div *ngIf="form.get('email').hasError('email') && form.get('email').touched"
                                        class="text-danger pt-1">
                                        A valid email is required
                                    </div>
                                    <div *ngIf="form.get('email').hasError('isProfane') && form.get('email').touched"
                                        class="text-danger pt-1">
                                        Please refrane from profanity
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <label>Message <span>*</span></label>
                                    <textarea formControlName="message" cols="30" rows="5" class="form-control"
                                        placeholder="Your message..."></textarea>
                                    <div *ngIf="form.get('message').hasError('required') && form.get('message').touched"
                                        class="text-danger pt-1">
                                        This field is required
                                    </div>
                                    <div *ngIf="form.get('message').hasError('minlength') && form.get('message').touched"
                                        class="text-danger pt-1">
                                        Minimum length of 20 characters required
                                    </div>
                                    <div *ngIf="form.get('message').hasError('maxlength') && form.get('message').touched"
                                        class="text-danger pt-1">
                                        Maximum length of 250 characters exceeded
                                    </div>
                                    <div *ngIf="form.get('message').hasError('isProfane') && form.get('message').touched"
                                        class="text-danger pt-1">
                                        Please refrane from profanity
                                    </div>
                                    <div class="text-muted pt-1">
                                        Character count: {{ characterCount }} / 250
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="button" (click)="submit()" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>