<div class="partner-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Partner</h2>
        </div>

        <div class="partner-slides">
            <div class="partner-item">
                <a href="https://paxxio.co.za" target="_blank" style="background-color:#252B2D ;"><img
                        src="https://paxxio.co.za/wp-content/uploads/2024/01/Artboard-13paxxio-Copy-290x100.png"
                        alt="image"></a>
            </div>
        </div>
    </div>
</div>