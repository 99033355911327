import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResponsiveService {
    constructor(private breakpointObserver: BreakpointObserver) { }

    public isMobile(): Observable<boolean> {
        return this.breakpointObserver.observe(Breakpoints.Handset).pipe(
            map((result) => result.matches)
        );
    }
}
