import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { ContactStateService } from '../contact/contact.state';
import { Router } from '@angular/router';
import { CreateLeadRequest } from '@app/requests';
import { vulgarLanguageValidator } from '@app/validators/vulgarity-validator';

@Component({
    selector: 'app-coming-soon',
    templateUrl: './coming-soon.component.html',
    styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit {
    form: UntypedFormGroup
    characterCount: number = 0;
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    myInterval: any;

    @Input() date: string;

    constructor(private _state: ContactStateService,
        private _router: Router,
        private _formBuilder: UntypedFormBuilder) { }

    ngOnInit() {
        this.myInterval = setInterval(() => {
            this.commingSoonTime();
        }, 0);
        this._buildForm();
    }

    _buildForm() {
        this.form = this._formBuilder.group({
            name: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(20), vulgarLanguageValidator()]],
            email: ['', [Validators.required, Validators.email, vulgarLanguageValidator()]],
            message: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(250), vulgarLanguageValidator()]],
        });
        // Subscribe to valueChanges of the 'message' form control
        this.form.get('message').valueChanges.subscribe(value => {
            this.characterCount = value.length;
        });
    }

    submit() {
        if (this.form.invalid) {
            this._state.alertUI('Please fill out all fields before submitting', 'error');
            return;
        }

        this._state.submit(<CreateLeadRequest>{
            email: this.form.controls.email.value,
            leadType: 2,
            capturedData: JSON.stringify({
                name: this.form.controls.name.value,
                message: this.form.controls.message.value,
            }),
        }, () => {
            setTimeout(() => {
                this._router.navigate(['']);
            }, 1000);
        });
    }

    commingSoonTime = () => {
        const endTimeParse = (Date.parse(this.date)) / 1000;
        const now = new Date();
        const nowParse = (Date.parse(now.toString()) / 1000);
        const timeLeft = endTimeParse - nowParse;
        const days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - (days * 86400)) / 3600);
        let minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600)) / 60);
        let seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
        if (hours < 10) { hours = 0 + hours; }
        if (minutes < 10) { minutes = 0 + minutes; }
        if (seconds < 10) { seconds = 0 + seconds; }
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
    }

}