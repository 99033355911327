import { CommonModule } from '@angular/common';
import { HeaderThreeComponent } from './header-three.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [
        HeaderThreeComponent,
    ],
    exports: [
        HeaderThreeComponent,
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class HeaderModule {
}
