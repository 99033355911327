//local
// const strapi = "fac044c394f58685ffdc70f29be5b60d7a47d36c1836ac97fa2edfd9dc30760434872c70b955ff123f15f7356f1437469c6bac8dab89cad5d2411443c81c82d41b16639b5c13fca0856739817408e5c5f2d04fc90d8b8233f484fcbd5a5b491cf067456d8e5c608c16c4af98aa19787fd61145046c654605157deae199b80162";
//dev
// const strapi = "94e4810d2d298dd5b9bb120956fe88a5ede06e7cf3c55dda45c2a93394902d08974bdfb3311c7ed9ee39a63dabb1cba97a601e6a8eecb511eb7b6560d02aa1a1f3ae27600b57fb70ecdf2968de092e629e858c52566d51001d36f0c003b77443bcb83d0249a2fc2a39a872d1b8dbeb1cecae8acd8476ba0ad64a46cd987ba572";
//prod
const strapi = "15f36d5899b97564123432e395a4149c3a5e4521fb06fbaa22c9ba3a2f5657540d47e745d56f58a328e8b2bfaae7b00e3291f78b083360f691aedd35c4b4e6d59f54ec309dea771ba062d138f6aff5ec621c5b198d67b0a5b1ca8b1ab4e0252f7477dc2292d13e02e30b337be395585c2d17f1a2dcc1dfd222ec3fbd689a1491";
const API_URL = 'https://api.yebokenny.com/';
const STRAPI_URL = 'https://cms.yebokenny.com/api';

// const CHECK_URL = 'localhost:7125';
const CHECK_URL = 'api.yebokenny.com';
// const CHECK_URL = 'cms.paxxio.co.za';

// const API_URL = 'https://9afa-197-91-187-10.ngrok-free.app/';
// const STRAPI_URL = 'http://localhost:1337/api';
export const Keys = {
    strapi,
    API_URL,
    STRAPI_URL,
    CHECK_URL
}