import { AppProcessResponse, ContactUsResponse, VideoResponse } from "@app/responses";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StateService } from "@app/services";
import { StrapiService } from "@app/services/strapi.service";
import { SubmitFormRequest } from "@app/requests";

interface VideoState {
    video: VideoResponse,    
    isLoading: boolean,
}


const initialState: VideoState = {
  video: undefined,    
    isLoading: false,
}

@Injectable({
    providedIn: 'root',
  })
export class VideoStateService extends StateService<VideoState>{    

    
    video$: Observable<VideoResponse> = this.select((state)=>{
        return state.video;
    });

    isLoading$: Observable<boolean> = this.select((state)=>{
        return state.isLoading;
    });
    
    constructor(private strapiService: StrapiService){
        super(initialState);        
    }


    
  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  load(){
    this.getInfo();
  }

  getInfo(){
    this.setLoading(true);
    this.strapiService.getVideo().subscribe(c => {
        this.setLoading(false);
        this.setState({video: c});
    });
  }
  setLoading(value: boolean){
    this.setState({isLoading: value});
  }
}