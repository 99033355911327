import { Business, Category } from "@app/models";
import { BusinessService, CategoryService, SearchService, StateService } from "@app/services";

import { ContactUsResponse } from "@app/responses";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StrapiService } from "@app/services/strapi.service";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { UserType } from "@app/constants";
import { map } from "rxjs/operators";

interface FooterState {
    businesses: Business[],
    isLoading: boolean,
    contact: ContactUsResponse,    
    hasCompletedFirstSearch: boolean,
}


const initialState: FooterState = {
    businesses: [],
    isLoading: false,
    contact: undefined,
    hasCompletedFirstSearch: false,
}

@Injectable({
    providedIn: 'root',
  })
export class FooterStateService extends StateService<FooterState>{    
    
    businesses$: Observable<Business[]> = this.select((state)=>{
        return state.businesses;
    });

    isLoading$: Observable<boolean> = this.select((state)=>{
        return state.isLoading;
    });

    hasCompletedFirstSearch$: Observable<boolean> = this.select((state)=>{
        return state.hasCompletedFirstSearch;
    });

    
    contact$: Observable<ContactUsResponse> = this.select((state)=>{
      return state.contact;
    });
    
    constructor(private searchService: SearchService, private strapiService: StrapiService){
        super(initialState);        
    }


    
  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------
  
  reset(){
    this.setState({
        isLoading: false,
        hasCompletedFirstSearch: false,
        businesses:[],
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  search(query: string){
    
    this.setState({isLoading: true, hasCompletedFirstSearch: true});
    this.searchService.search(query).pipe(
        map(response => response.data),
      ).subscribe(data =>{
        this.setState({businesses:  data});
        this.setState({isLoading: false});
      });
  }

  getInfo(){
    this.strapiService.getContactInfo().subscribe(c => {
        this.setState({contact: c});
    });
  }
}