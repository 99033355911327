import { Observable, of } from "rxjs";
import { SettingsService, StateService } from "@app/services";

import { Injectable } from "@angular/core";
import { SiteSettings } from "@app/models";
import { SiteSettingsResponse } from "./responses";

interface GlobalState {
  siteSettings: SiteSettings,
}


const initialState: GlobalState = {

  siteSettings: undefined,
}

@Injectable({
  providedIn: 'root',
})
export class GlobalStateService extends StateService<GlobalState>{

  siteSettings$: Observable<SiteSettings> = this.select((state) => {
    return state.siteSettings;
  });

  constructor(
    private _settingsService: SettingsService) {
    super(initialState);
  }



  // -----------------------------------------------------------------------------------------------------
  // @ UTILITY
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ API CALLS
  // -----------------------------------------------------------------------------------------------------
  load() {
    this.getSiteSettings();
  }

  getSiteSettings(): Observable<any> {
    this._settingsService.siteSettings().subscribe(res => {
      if (res.data) {
        this.setState({ siteSettings: res.data });
        return of(true);
      }
    });
    return of(false);
  }
}