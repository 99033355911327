<ng-container *ngIf="info$ | async as info">

    <div class="instagram-area" *ngIf="info.data.attributes.instagramUrl">
        <div class="container-fluid">
            <div class="instagram-title">
                <a [href]="info.data.attributes.instagramUrl" target="_blank"><i class='bx bxl-instagram'></i> Follow us
                    on @yebokenny</a>
            </div>
        </div>
    </div>
</ng-container>