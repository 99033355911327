import { Component, OnInit } from '@angular/core';

import { AboutStateService } from '../about/root.state';
import { Observable } from 'rxjs';
import { PlanDto } from '@app/models';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  $plans: Observable<PlanDto[]> = this._state.availablePlans$;
  constructor(private _state: AboutStateService) { }

  ngOnInit(): void {
    this._state.plans();
  }

  getPeriodType(arg0: number): string {
    switch (arg0) {
      case 0:
        return 'Once off';
      case 1:
        return 'Daily';
      case 2:
        return 'Weekly';
      case 3:
        return 'Monthly';
      case 4:
        return 'Semi Annually';
      case 5:
        return 'Yearly';
      default:
        return '';
    }
  }

}
