import { Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { VideoResponse } from '@app/responses';
import { VideoStateService } from './video.state';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  
  $video: Observable<VideoResponse> = this._state.video$;
   
  constructor(private _state: VideoStateService) { }

  ngOnInit(): void {
    this._state.getInfo();
  }

}
